import React from "react"

import Brand from "../../Elements/Brand"
import Message from "../../Elements/Message"

const ProofOfPaymentBankDetails = () => {
  return (
    <Message color="info">
      <p className="is-size-6 mb-2">
        Thank you for ordering your medicines from <Brand />. Through this form,
        you can upload your proof of payment for your order. You may deposit
        your payment to:
      </p>
      <p className="is-size-6 mb-0 has-text-weight-bold">Unionbank</p>
      <p className="is-size-6 my-0">Account name: MG HEALTH SOLUTIONS INC</p>
      <p className="is-size-6 my-0">Account number: 0031 9000 0497</p>
    </Message>
  )
}

export default ProofOfPaymentBankDetails

import React from "react"

import ProofOfPayment from "components/ProofOfPayment"
import PrivateRoute from "layout/PrivateRoute"

import { GATSBY_ENV_SHOW_OTP } from "gatsby-env-variables"

export default ({ location }) => (
  <PrivateRoute isPrivate={GATSBY_ENV_SHOW_OTP} location={location}>
    <ProofOfPayment location={location} />
  </PrivateRoute>
)
